import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';

const HeaderImage = () => {
    const [internets, setInternets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingInternet, setEditingRural] = useState(null);
    const [title, setTitle] = useState("");
    const [sub, setSub] = useState("");
    const [text, setText] = useState("");
    const [foto, setFoto] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchInternets();
    }, []);

    const fetchInternets = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/internets`);
            setInternets(response.data);
        } catch (error) {
            console.error("Erro ao buscar os internets:", error);
        }
    };

    const handleOpenModal = () => {
        setIsEditing(false);
        setEditingRural(null);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTitle("");
        setSub("");
        setText("");
        setFoto(null);
        setPreviewImage(null);
    };

    const handleEditRural = (internet) => {
        setIsEditing(true);
        setEditingRural(internet);
        setTitle(internet.title);
        setSub(internet.sub);
        setText(internet.text);
        setPreviewImage(`${process.env.REACT_APP_API_IMG}/assets/internets/${internet.foto}`);
        setShowModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteRural = async (internetId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/internets/${internetId}`);
            handleCloseDeleteModal(); // Fechar o modal após a exclusão
            fetchInternets(); // Atualizar a lista de internets

            toast.success('Internet deletado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
        } catch (error) {
            console.error("Erro ao deletar o internet:", error);
        }
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalRuralId, setDeleteModalInternetId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("sub", sub);
        formData.append("foto", foto);
        formData.append("text", text);

        try {
            if (isEditing && editingInternet) {
                await axios.put(`${process.env.REACT_APP_API_URL}/internets/${editingInternet._id}`, formData);
                toast.success('Formulário editado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/internets`, formData);
                toast.success('Formulário cadastrado com sucesso!', { position: 'top-right', autoClose: 2000 }); // 3000 milissegundos = 3 segundos
            }

            fetchInternets();
            handleCloseModal();
        } catch (error) {
            console.error("Erro ao cadastrar/editar o internet:", error);
        }
    };

    const handleFotoChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            const imageURL = URL.createObjectURL(selectedImage);
            setPreviewImage(imageURL);
            setFoto(selectedImage);
        }
    };

    const [imagemFundo, setImagemFundo] = useState('');

    return (
        <div className="container-fluid">
            <div className="row">
                <Card>
                    <Card.Body>
                        <div className="title">
                            <Button onClick={handleOpenModal}>Cadastrar Internet</Button>
                        </div>
                        <div className="table-container">
                            <table className="tabela">
                                <thead>
                                    <tr>
                                        <th className="coluna1">Imagem</th>
                                        <th className="coluna2">Título</th>
                                        <th className="coluna3">Subtítulo</th>
                                        <th className="coluna4">Texto</th>
                                        <th className="coluna5">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {internets.map((internet) => (
                                        <tr key={internet._id}>
                                            <td>
                                                <div className="internet-img">
                                                    <img src={`${process.env.REACT_APP_API_IMG}/assets/internets/${internet.foto}`} alt="Internet" />
                                                </div>
                                            </td>
                                            <td>{internet.title}</td>
                                            <td>{internet.sub}</td>
                                            <td><TextRender htmlContent={internet.text} /></td>
                                            <td>
                                                <div className="botoes">
                                                    <div className="botao">
                                                        <Button variant="outline-success" onClick={() => handleEditRural(internet)}>
                                                            <i class="fa fa-edit" aria-hidden="true"></i>
                                                        </Button>
                                                    </div>
                                                    <div className="botao">
                                                        <Button
                                                            variant="outline-danger"
                                                            onClick={() => {
                                                                setDeleteModalInternetId(internet._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                        </Button>
                                                        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Confirmar Exclusão</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                Tem certeza que deseja excluir este internet? {deleteModalRuralId}
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                                                    Cancelar
                                                                </Button>
                                                                <Button variant="danger" onClick={() => { handleDeleteRural(deleteModalRuralId); setShowDeleteModal(false); }}>
                                                                    Excluir
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <div className="row">
                <Modal show={showModal} onHide={handleCloseModal} size="lg" className="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? "Editar Internet" : "Cadastrar Internet"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>

                            <FormGroup>
                                <Form.Label>Título</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Subtítulo</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <FormControl type="text" value={sub} onChange={(e) => setSub(e.target.value)} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Texto</Form.Label>
                                <div className='text-icon'>
                                    <span className="input-group-text-area" id="addon-wrapping"><i className="fa fa-fw fa-pencil-square-o" style={{ fontSize: "1.0em", color: "#000000" }} /></span>
                                    <ReactQuill value={text} onChange={setText} style={{ width: '100%', height: '80px' }} required />
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="foto-preview">
                                    <Form.Label>Foto</Form.Label>
                                    <FormControl type="file" onChange={handleFotoChange} required />
                                    {previewImage && <img src={previewImage} alt="Preview" />}
                                </div>
                            </FormGroup>
                            <br />
                            <Button variant="primary" type="submit">
                                {isEditing ? "Editar" : "Cadastrar"}
                            </Button>{' '}
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Fechar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};

export default HeaderImage;