import React, { useEffect, useState } from "react";
import TitleAnim from "../../components/TitleAnim";
import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeInCard from "../../components/FadeInCard";

import './styles.css'; // Importe o estilo CSS para a animação
import CardVideoExplicativo from "../../components/HomeComponents/CardVideoExplicativo";

const Videos = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row content">
                <div className="col-sm-12">
                    <div className="title">
                        <h1><TitleAnim texts={['Vídeos Explicativos']} /></h1>
                    </div>
                </div>
            </div>
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div>
                        <h1><TitleAnim texts={['Vídeos Explicativos']} /></h1>
                            <CardVideoExplicativo />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Videos;