import React, { useEffect, useState } from "react";
import TitleAnim from "../../components/TitleAnim";
import { Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import FadeInCard from "../../components/FadeInCard";

import './styles.css'; // Importe o estilo CSS para a animação

const Private = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="title">
                    <h1><TitleAnim texts={['Política de Privacidade']} /></h1>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div>
                        <FadeInCard
                            title="Título do Card"
                            text="There are many variations of passages of Lorem Ipsum available, but the majority 
            have suffered alteration in some form, 
            by injected humour, or randomised words which don't look even slightly believable."
                            footer="Teste de footer"
                        />

                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <FadeInCard
                        title="Título do Card2"
                        text="There are many variations of passages of Lorem Ipsum available, but the majority 
            have suffered alteration in some form, 
            by injected humour, or randomised words which don't look even slightly believable."
                        footer="Teste de footer2" />
                </div>

            </div>
        </div>
    );
}
export default Private;