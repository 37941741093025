import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'; // Importe o estilo CSS para a animação

const FadeInCard = ({ title, text, sub, foto, footer, button, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <Card className={`fade-in-card ${isVisible ? 'visible' : ''}`} {...rest}>
          <Card.Header>
          {title && <Card.Title>{title}</Card.Title>}
          </Card.Header>
          <Card.Img variant="top" src={foto} alt={title} />
        <Card.Body>
          {text && <Card.Text>{text}</Card.Text>}
          {sub && <Card.Text>{sub}</Card.Text>}
        </Card.Body>
        <Card.Footer>
        <Card.Link>
            {footer && <Card.Text>{footer}</Card.Text>}
            {button && <div>{button}</div>}
          </Card.Link>
        </Card.Footer>
      </Card>
    </>
  );
};

export default FadeInCard;
