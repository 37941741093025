import React from "react";

import CardSale from "../../components/HomeComponents/CardSale";
import CardOffer from "../../components/HomeComponents/CardOffer";
import SliderHome from "../../components/HomeComponents/SliderHome";
import CardPhone from "../../components/HomeComponents/CardPhone";
import CardRural from "../../components/HomeComponents/CardRural";

import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardVideoTv from "../../components/HomeComponents/CardVideoTv";
import Copywriting from "../../components/HomeComponents/CardOffer/Copywriting";
import HeaderImage from "../../components/InternetComponents/HeaderImage";


const Internet = () => {

    return (
        <div className="container-fluid table">
            <div className="row content">
                <div className="col-sm-12">
                    <h3 className="bem-vindo">
                        Página Internet!
                    </h3>
                    <p className="bem-vindo-p">
                        Bem-vindo ao Sistema de Gerenciamento de Conteúdo (CMS) do nosso site.
                        Este é o ponto de controle central para explorar e customizar todos os elementos essenciais
                        que compõem o nosso site. Nesta seção, você terá a capacidade de personalizar e administrar cada detalhe.
                        Abaixo, destacamos as opções específicas disponíveis para editar ou
                        cadastrar os principais componentes da nossa página inicial.
                    </p>
                    <p className="bem-vindo-p">
                        Para personalizar as demais páginas do nosso site, acesse as opções disponíveis no menu lateral.
                        Este recurso intuitivo oferece acesso rápido e direto a
                        todas as áreas relevantes para a edição e atualização de conteúdo.
                    </p>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="carousel-h3">
                        <h3><i class="fa fa-wifi" aria-hidden="true" /> Página Internet</h3>
                        <span>Atenção para as dimensões das fotos para não distorcer o layout do site! Recomendação:  (1780x665 pixels)</span>
                    </div>
                    <div className="col-sm-12">
                        <br />
                        <HeaderImage />
                    </div>
                </div>

                
            </div>
        </div>
    );
};

export default Internet;